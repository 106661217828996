.full-screen-container {
    display: flex;
    flex-direction: column;
    height: 100dvh; /* 100% of the viewport height */
  }
  
  .transcripts-container {
    padding: 5px;
    flex-grow: 1; /* Fill the remaining space */
    min-height: 0; /* Allow the container to shrink below its content size */
    /* background-color: lightblue; Set your desired background color */
    overflow: auto; /* Enable scrolling if content overflows */
  }
  
  .controller-container {
    /* background-color: lightgreen; Set your desired background color */
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

/* Normal Text Styling */
/* Normal Text Styling */
 /* Normal Text Styling */
 .normal-text1 {
    color: #32cd32; /* Lime Green */
  }

  .normal-text2 {
    color: #008080; /* Dark Turquoise */
  }

  .normal-text3 {
    color: #5c6bc0; /* Light Indigo */
  }  
  
  


  /* Focus Text Styling */
  .focus-text1 {
    color: #006600; /* Slightly lighter Green */
    font-weight: bold;
  }
  
  .focus-text2 {
    color: #00494c; /* Dark Teal */
    font-weight: bold;
  }
  
  .focus-text3 {
    color: #512da8; /* Purple */
    font-weight: bold;
  }
  
  /* Background color for focus */
  .focus-background {
    background-color: #ffdab9; /* Light Peach */
  }